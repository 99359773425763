import React from 'react';
import Layout from '../components/layout';
import { graphql } from 'gatsby';

const PoemTemplate = ({ data }) => {
  const post = data.markdownRemark;
  const titleWithSpaces = post.frontmatter.title
                .replace(/_/g, ' ')
                .toUpperCase();
  
  const bannerImage = post.frontmatter.image;

  return (
    <Layout>
      {bannerImage && (
        <section
          className="bg-center bg-cover h-32 sm:h-52 w-full"
          style={{
            backgroundImage: `url(${bannerImage})`,
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            backgroundBlendMode: 'lighten', 
            backgroundPosition: '0 18%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
        </section>
      )}

      <section className="container mx-auto py-8">
        <h1 className="tracking-[10px] text-3xl sm:text-5xl mb-0 sm:mb-4 text-red-800">{titleWithSpaces}</h1>
        <p className="text-gray-500 mb-8">{post.frontmatter.date}</p>
        <div
            className="prose prose-lg space-y-6"
            style={{
                lineHeight: '1.4',
            }}
            dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </section>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        image
      }
      html
    }
  }
`;

export default PoemTemplate;
